.company-logo {
  width: 71px;
  margin-top: 2rem;
  margin-left: 1.5rem;
}

@media (max-width: 576px) {
  .company-logo {
    width: 44.67px;
    margin-top: 1.25rem;
    margin-left: 1.5rem;
  }
}

.banner__image {
  background-size: cover;
  background-repeat: no-repeat;
  border:none;
}

@media (min-width: 464px) and (max-width: 575.98px) {
  .banner__heading {
    font-size: 60px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.2px;
  }
}

@media (min-width: 768px) and (max-width: 800px) {
  .banner__heading {
    font-size: 60px !important;
    font-weight: 700 !important;
    line-height: 60px !important;
    letter-spacing: -2px !important;
  }
}
