$card-gap: 24px;

.recommendations-container__card-list {
  gap: $card-gap $card-gap;

  @include media-breakpoint-down(sm) {
    margin-bottom: 0 !important;
  }

  .recommendation-card {
    flex: 0 1 100%;
    cursor: pointer;

    @include media-breakpoint-up(sm) {
      flex: 0 1 calc(50% - #{$card-gap - 12});
    }

    @include media-breakpoint-up(md) {
      flex: 0 1 calc(33.333% - #{$card-gap - 8});
    }

    @include media-breakpoint-up(lg) {
      flex: 0 1 calc(25% - #{$card-gap - 6});
    }
  }
}

.recommendations-container__heading {
  overflow-wrap: break-word;
}

